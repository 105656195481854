import React from 'react';
import { css } from '@emotion/react';
import { Row, Col } from 'emotion-flex';
import { MonoFontLabel } from '../common/typography';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';
import Text from '../Homepage/Text';
import containerBackgroundImageDe from '../../assets/svgs/rezepteDigainfo/rezepte.svg';
import containerBackgroundImageEn from '../../assets/svgs/rezepteDigainfo/rezepte-en.svg';
import Container from '../Homepage/Container';
import { markdownText } from '../../pages/doctors';
import { useIntl } from 'react-intl';

const col = css({
  paddingLeft: '0',
  minHeight: 'calc(75.7vw)',
  paddingTop: 'calc(90vw + 20px)',
  paddingBottom: '30vw',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',

  [presets.sm]: {
    paddingLeft: '3%',
    minHeight: 'calc(75.7vw)',
    paddingTop: '50vw',
    paddingBottom: '20vw',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },

  [presets.md]: {
    paddingLeft: '3%',
    minHeight: 'calc(75.7vw)',
    paddingTop: '5vw',
    paddingBottom: '15vw',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },

  [presets.lg]: {
    paddingLeft: '3%',
    minHeight: 'calc(65.7vw)',
    paddingTop: '5vw',
    paddingBottom: '15vw',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },

  [presets.xl]: {
    paddingTop: '5vw',
    minHeight: 'calc(60.7vw)',
    paddingLeft: '5%',
  },

  [presets.xl]: {
    paddingTop: '0',
    paddingLeft: '5%',
    minHeight: 'calc(45.7vw)',
    paddingBottom: '5.8vw',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
});

export interface FeatureHeaderProps {
  prefix: string;
  title: string;
  text: string;
}

export default function FeatureHeader({
  prefix,
  title,
  text,
}: FeatureHeaderProps) {
  const intl = useIntl();

  const outerContainer = css({
    backgroundImage: `url(${
      intl.locale === 'de'
        ? containerBackgroundImageDe
        : containerBackgroundImageEn
    })`,
    backgroundRepeat: 'no-repeat',

    backgroundPosition: 'right top',
    backgroundSize: '70%',
    marginBottom: '-5.8vw',

    [presets.sm]: {
      backgroundPosition: 'right top',
      backgroundSize: '55%',
      marginBottom: '-5.8vw',
    },

    [presets.md]: {
      backgroundPosition: 'right 40%',
      backgroundSize: '50%',
      marginBottom: '-5.8vw',
    },

    [presets.lg]: {
      backgroundPosition: 'right top',
      backgroundSize: '50%',
      marginBottom: '-5.8vw',
    },

    [presets.xl]: {
      backgroundPosition: 'right top',
      backgroundSize: '40%',
      marginBottom: '-5.8vw',
    },
  });

  return (
    <div css={outerContainer}>
      <Container>
        <Row>
          <Col xs={12} sm={8} md={6} css={col}>
            <MonoFontLabel css={{ [presets.md]: { fontSize: '0.875rem' } }}>
              {prefix}
            </MonoFontLabel>

            <Text
              css={{
                fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
                fontSize: 32,
                color: '#160B2C',
                marginBottom: '1.5rem',
                [presets.md]: { fontSize: 38 },
                [presets.xl]: { fontSize: 48 },
              }}
            >
              {markdownText(title)}
            </Text>
            <Text
              css={{
                fontSize: 16,
                lineHeight: 1.5,
                fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                fontWeight: 500,
              }}
            >
              {markdownText(text)}
            </Text>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
