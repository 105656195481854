import React, { FC } from 'react';

import { ReactComponent as TopWave } from '../../assets/svgs/rezepteDigainfo/form-content-top.svg';
import { ReactComponent as BottomWave } from '../../assets/svgs/rezepteDigainfo/form-content-bottom.svg';
import PrescriptionContent from './PrescriptionContent';
import { useIntl } from 'react-intl';

export interface Step {
  title: string;
  text: string;
}

const PrescriptionSection: FC = () => {
  const intl = useIntl();

  const steps: Step[] = [
    {
      title: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.title.one',
        defaultMessage: 'Rezept übergeben',
      }),
      text: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.text.one',
        defaultMessage:
          'Lade dein Rezept einfach über das bereitgestellte Formular hoch.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.title.two',
        defaultMessage: 'Rezept Service',
      }),
      text: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.text.two',
        defaultMessage:
          'Wir reichen dein Rezept bei deiner Krankenkasse für dich ein.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.title.three',
        defaultMessage: 'Freischaltung',
      }),
      text: intl.formatMessage({
        id: 'rezepte.digainfo.prescription.text.three',
        defaultMessage:
          'Du erhältst üblicherweise innerhalb von wenigen Tagen den Freischalt-Code von deiner Krankenkasse. Diesen gibst du in der App ein und startest dein Programm.',
      }),
    },
  ];

  return (
    <>
      <TopWave
        width="100vw"
        height="auto"
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
        }}
      />
      <div
        css={{
          marginTop: -1,
          backgroundColor: '#489F9D',
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <PrescriptionContent steps={steps} />
      </div>

      <BottomWave
        width="100%"
        height="83"
        css={{ width: '100%', height: 'auto' }}
      />
    </>
  );
};

export default PrescriptionSection;
