import React, { FC } from 'react';

import { ReactComponent as Support } from '../../assets/svgs/rezepteDigainfo/support.svg';
import Container from '../Homepage/Container';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';
import { css } from '@emotion/core';
import { ReactComponent as PhoneIcon } from '../../assets/svgs/header/phone.svg';
import { useIntl } from 'react-intl';
import Text from '../Homepage/Text';

const serviceStyle = css`
  font-size: 18px;
  font-family: ${['Source Code Pro'].concat(SYSTEM_FONTS).join(', ')};
  font-weight: 600;
  color: #828181;
  text-transform: uppercase;
`;

const IconStyles = css({
  fill: '#000000',
  marginRight: 5,
});

const textStyle = css({
  fontSize: 24,
  fontWeight: 600,
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  color: '#000000',
});

const ContactSection: FC = () => {
  const intl = useIntl();

  return (
    <Container
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        flexDirection: 'column',
        textAlign: 'center',
        marginBottom: 50,
      }}
    >
      <Support />
      <Text
        css={{
          marginTop: 15,
          fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 500,
          fontSize: 32,
          color: '#160B2C',
          marginBottom: '1.5rem',
          [presets.md]: { fontSize: 38 },
          [presets.xl]: { fontSize: 48 },
          maxWidth: 500,
        }}
      >
        {intl.formatMessage({
          id: 'rezepte.digainfo.contact.header',
          defaultMessage: 'Fragen? Wir helfen dir jederzeit weiter!',
        })}
      </Text>

      <span css={[serviceStyle]}>Service-Hotline:</span>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          whiteSpace: 'nowrap',
        }}
      >
        <PhoneIcon css={IconStyles} />
        <span css={[textStyle]}>030 3080 9546</span>
      </div>
      <span css={serviceStyle}>
        {intl.formatMessage({
          id: 'header.hours',
          defaultMessage: '(Mo–Fr 9–18 Uhr)',
        })}
      </span>
    </Container>
  );
};

export default ContactSection;
