import React from 'react';
import { WrappedComponentProps, useIntl } from 'react-intl';
import { Layout } from '../components';
import FeatureHeader from '../components/RezepteDigainfo/FeatureHeader';
import PrescriptionSection from '../components/RezepteDigainfo/PrescriptionSection';
import ContactSection from '../components/RezepteDigainfo/ContactSection';

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
}

type Props = OwnProps & WrappedComponentProps;

const RezepteDigainfo: React.FC<Props> = ({ pathContext: { locale } }) => {
  const intl = useIntl();

  return (
    <Layout isNewLayout hideCallToAction>
      <FeatureHeader
        prefix={intl.formatMessage({
          id: 'rezepte.digainfo.feature.header.prefix',
          defaultMessage: 'Kostenfrei und schnell',
        })}
        title={intl.formatMessage({
          id: 'rezepte.digainfo.feature.header.header',
          defaultMessage: '<b>Rezept</b>-Service',
        })}
        text={intl.formatMessage({
          id: 'rezepte.digainfo.feature.header.text',
          defaultMessage:
            'Für die Nutzung von <b><i>Cara Cara für Reizdarm</i></b> benötigst du einen 16-stelligen Freischalt-Code zur Aktivierung der App. Diesen Code erhältst du von deiner Krankenkasse nach Einreichung des Rezeptes.\n\nDas kannst du entweder selbst tun oder über unseren kostenfreien Rezept-Service erledigen lassen. Der Service ist optional und hat keinen Einfluss auf die mögliche Nutzung von Cara Care.',
        })}
      />
      <PrescriptionSection />
      <ContactSection />
    </Layout>
  );
};

export default RezepteDigainfo;
