import { css } from '@emotion/core';
import React, { FC, useEffect } from 'react';
import { Step } from './PrescriptionSection';
import { Col, Row } from 'emotion-flex';
import { SYSTEM_FONTS } from '../../styles/typography';
import styled from '@emotion/styled';
import { createWidget } from '@typeform/embed';
import { REZEPT_URL_INFO, REZEPT_URL_INFO_EN } from '../../utils/constants';
import { useIntl } from 'react-intl';

interface PrescriptionContent {
  steps: Step[];
}

const Container = styled.div`
  padding: 24px;
  margin: 0 auto;
  max-width: 1368px;

  @media (max-width: 768px) {
    padding: 12px;
    max-width: 100%;
  }
`;

const typeformContainer = css`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const rightSide = css`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  @media (min-width: 769px) {
    flex: 0 0 calc(50% - 10px);
  }
`;

const number = css({
  fontSize: '2.5rem',
  fontWeight: 700,
  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
  color: '#ffffff',
});

const header = css({
  fontSize: '2rem',
  fontWeight: 700,
  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
  color: '#ffffff70',
  marginBottom: 20,
});

const title = css({
  fontSize: 18,
  fontWeight: 700,
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  color: '#ffffff',
  marginBottom: 0,
});

const text = css({
  fontSize: 16,
  fontWeight: 500,
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  color: '#ffffff80',
  maxWidth: 400,
  wordWrap: 'break-word',
});

const contentWrapper = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const stepWrapper = css({
  display: 'flex',
  alignItems: 'flex-start',
});

const stepNumberWrapper = css({
  width: 40,
  display: 'flex',
  alignItems: 'center',
});

const itemWrapper = css({
  marginLeft: 20,
});

const PrescriptionContent: FC<PrescriptionContent> = ({ steps }) => {
  const intl = useIntl();

  useEffect(() => {
    const loadTypeform = async () => {
      const container = document.getElementById('typeformContainer');
      const widget = createWidget(
        intl.locale === 'de' ? REZEPT_URL_INFO : REZEPT_URL_INFO_EN,
        {
          container: container,
          hideFooter: true,
          hideHeaders: true,
          disableScroll: true,
          height: 825,
        }
      );

      widget.focus();
    };

    loadTypeform();
  }, [intl.locale]);

  return (
    <Container>
      <Row>
        <Col>
          <h1 css={header}>
            {intl.formatMessage({
              id: 'rezepte.digainfo.prescription.header',
              defaultMessage: 'So funktioniert’s',
            })}
          </h1>
          <div css={contentWrapper}>
            {steps.map((step, i) => (
              <div css={stepWrapper} key={i}>
                <div css={stepNumberWrapper}>
                  <p css={number}>{`${i + 1}.`}</p>
                </div>
                <div css={itemWrapper}>
                  <p css={title}>{step.title}</p>
                  <p css={text}>{step.text}</p>
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col css={rightSide}>
          <div css={typeformContainer} id="typeformContainer" />
        </Col>
      </Row>
    </Container>
  );
};

export default PrescriptionContent;
